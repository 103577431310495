<template>
  <div class="page">
    <div class="title">薪资发放记录</div>

    <!-- table -->
    <el-table
      id="table"
      :data="tableData"
      :header-cell-style="tableHeaderStyle"
      :cell-style="tableCellStyle"
      style="width: 100%"
    >
      <el-table-column prop="month" label="工资时间"></el-table-column>
      <!-- <el-table-column prop="name" label="统计时间"></el-table-column> -->
      <el-table-column
        prop="grant_staff_num"
        label="包含员工人数"
      ></el-table-column>
      <el-table-column prop="total_salary" label="总金额"></el-table-column>
      <!-- <el-table-column prop="name" label="服务费"></el-table-column> -->
      <el-table-column prop="create_time" label="发放时间"></el-table-column>
      <el-table-column prop="bank_name" label="发放渠道"></el-table-column>
      <el-table-column label="操作" width="130" fixed="right">
        <template v-slot:default="scope">
          <div style="display: flex; justify-content: center; width: 100%">
            <span class="s_operation" @click="checkdetail(scope.row.id)"
              >明细</span
            >
            <span class="s_operation" @click="exportinfo(scope.row.id)"
              >导出</span
            >
            <span class="s_operation" @click="exportCompanyList(scope.row.id)"
              >单位汇总导出</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="月考勤明细" :visible.sync="dialogVisible" width="80%">
      <el-table
        :data="grantdetail"
        :header-cell-style="tableHeaderStyle"
        :cell-style="tableCellStyle"
        style="width: 100%"
      >
        <el-table-column prop="real_name" label="姓名"></el-table-column>
        <el-table-column prop="job_number" label="工号"></el-table-column>
        <el-table-column prop="mobile" label="联系电话"></el-table-column>
        <el-table-column prop="entry_time" label="入职时间"></el-table-column>
        <el-table-column prop="idcard" label="身份证号"></el-table-column>
        <el-table-column prop="bank_deposit" label="开户行"></el-table-column>
        <el-table-column prop="bank_name" label="具体开户行"></el-table-column>
        <el-table-column prop="bank_no" label="银行卡号"></el-table-column>
        <el-table-column
          prop="payroll_company"
          label="发薪公司"
        ></el-table-column>
        <el-table-column prop="company_name" label="公司名称"></el-table-column>
        <el-table-column prop="department_name" label="单位"></el-table-column>
        <el-table-column prop="duty_name" label="项目"></el-table-column>
        <el-table-column prop="station_name" label="岗位名称"></el-table-column>
        <el-table-column prop="post_level" label="岗位类型"></el-table-column>
        <el-table-column prop="work_type" label="用工类别"></el-table-column>
        <el-table-column prop="white_amount" label="应发小计"></el-table-column>
        <el-table-column prop="white_amount" label="偿还白条"></el-table-column>
        <!-- <el-table-column
          prop="week_overtime_salary"
          label="周末加班费"
        ></el-table-column> -->
        <el-table-column
          prop="legal_overtime_salary"
          label="法定加班费"
        ></el-table-column>
        <el-table-column
          prop="replace_overtime_salary"
          label="替岗工资"
        ></el-table-column>
        <el-table-column
          prop="total_overtime_salary"
          label="获取加班总费用"
        ></el-table-column>
        <el-table-column
          prop="deduction_salary"
          label="扣款总金额"
        ></el-table-column>
        <el-table-column
          prop="labour_services"
          label="劳务发放"
        ></el-table-column>
        <el-table-column
          prop="labour_services_tax"
          label="劳务个税"
        ></el-table-column>
        <el-table-column prop="manager_amount" label="管理费"></el-table-column>
        <el-table-column
          prop="invoice_amount"
          label="结算开票额"
        ></el-table-column>
        <el-table-column prop="personal_tax" label="个税扣除"></el-table-column>
        <el-table-column prop="tax_amount" label="税金显示"></el-table-column>
        <el-table-column
          prop="is_deduct_social"
          label="是否扣除社保"
        ></el-table-column>
        <el-table-column
          prop="deduct_total_insurance_per"
          label="个人五险总扣款"
        ></el-table-column>
        <el-table-column
          prop="deduct_total_insurance_com"
          label="单位五险总扣款"
        ></el-table-column>
        <el-table-column
          prop="deduct_total_fund_per"
          label="个人公积金扣除总金额"
        ></el-table-column>
        <el-table-column prop="sub_total" label="应发小计"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="create_time" label="添加时间"></el-table-column>
        <el-table-column prop="update_time" label="更新时间"></el-table-column>
        <el-table-column prop="total" label="总计"></el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :total="page1.total"
        :page-size="page1.limit"
        :current-page.sync="page1.page"
        @current-change="getDetailCurrent"
      >
      </el-pagination>
    </el-dialog>

    <el-pagination
      layout="prev, pager, next"
      :total="page.total"
      :page-size="page.limit"
      :current-page.sync="page.page"
      @current-change="loadData"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  inject: ["reload"],
  data() {
    return {
      search: {
        keyword: "",
      },
      tableData: [],
      page: {
        limit: 10,
        page: 1,
        total: 0,
      },
      page1: {
        limit: 10,
        page: 1,
        total: 0,
        currentId: 0,
      },
      tableHeaderStyle: {
        backgroundColor: "#F5F8FA",
        textAlign: "center",
      },
      tableCellStyle: {
        textAlign: "center",
      },
      grantdetail: [],
      dialogVisible: false,
    };
  },
  created() {
    this.loadData = this.loadDataFactory();
    this.loadData();
  },
  methods: {
    ...mapActions("pay", [
      "loadattendance",
      "getattendancedetail",
      "getexportinfo",
      "postattendance",
      "loadgrantList",
      "getgrantDetail",
      "exportCompanyExcel",
    ]),

    loadDataFactory() {
      let params = {};
      return () => {
        params.page = this.page.page;
        params.limit = this.page.limit;
        let loading = this.$loading();
        this.loadgrantList(params)
          .then((res) => {
            console.log(res);
            this.tableData = res.data.data.list;
            this.page.total = res.data.data.total;
            this.$nextTick(() => {
              loading.close();
            });
          })
          .catch((e) => {
            this.$message.error(e.msg);
            loading.close();
          });
      };
    },
    checkdetail(id) {
      let loading = this.$loading();

      let params = {
        size: this.page1.limit,
        page: this.page1.page,
      };
      if (id) {
        this.page1.currentId = id;
      }
      params.id = this.page1.currentId;
      this.getgrantDetail(params)
        .then((res) => {
          console.log(res);
          this.grantdetail = res.data.data.list;
          this.page1.total = res.data.data.total;
          this.dialogVisible = true;
          loading.close();
        })
        .catch((e) => {
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
        });
    },
    getDetailCurrent(e) {
      console.log(e);
      let loading = this.$loading();
      let params = {
        size: this.page1.limit,
        page: e,
      };
      params.id = this.page1.currentId;
      this.getgrantDetail(params)
        .then((res) => {
          console.log(res);
          this.grantdetail = res.data.data.list;
          this.page1.total = res.data.data.total;
          this.dialogVisible = true;
          loading.close();
        })
        .catch((e) => {
          this.$message.error({
            message: e.msg,
            showClose: true,
          });
        });
    },
    //导出数据表
    exportinfo(id) {
      let params = {};
      params.id = id;
      params.type = 2;
      params.name = "薪资发放记录.xlsx";
      this.getexportinfo(params);
    },

    // 单位汇总导出
    exportCompanyList(id) {
      let params = {};
      params.id = id;
      params.type = 3;
      params.name = "单位汇总记录.xlsx";
      this.getexportinfo(params);
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  position: relative;
  padding-bottom: 50px;
  min-height: calc(100% - 50px);
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: #1c2438;
}

.filter_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;

  .el-date-editor {
    height: 36px;
    margin-right: 10px;

    ::v-deep .el-input__inner {
      height: 36px;
    }
  }

  .filter_search {
    display: flex;
    align-items: center;

    .filter_search_select {
      width: 80px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }

      ::v-deep .el-input__icon {
        line-height: 36px;
      }
    }

    .filter_search_input {
      width: 350px;
      height: 36px;
      margin-right: 10px;

      ::v-deep .el-input__inner {
        height: 36px;
      }
    }
    > div:not(:first-child) {
      margin-left: 20px;
    }
  }

  .filter_btns {
    display: flex;
    align-items: center;

    > div:not(:first-child) {
      margin-left: 20px;
    }
  }
}

.operations {
  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 14px;
    color: #406bff;
    white-space: nowrap;
    cursor: pointer;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    > div {
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.s_operation {
  color: #4db9d5;
  margin-right: 5px;
  display: inline-block;
  width: 33%;
  box-sizing: border-box;
}
.s_operation:hover {
  cursor: pointer;
}
</style>
